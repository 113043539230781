<template>
    <modal ref="modalSeleccionarLeechero" :titulo="`Seleccionar tu ${$config.vendedor}` " tamano="modal-lg" adicional="Seleccionar" cancelar="Cerrar" no-adicional>
        <div class="row mx-0 j-center pb-3 mb-3">
            <div class="col-10">
                <div class="position-relative">
                    <el-input v-model="codigo_leechero" prefix-icon="icon-search f-18" :placeholder="`Buscar ${$config.vendedor}`" class="w-100 br-20 input-search-cupon" />
                    <!-- button -->
                    <button type="button" class="btn-buscar f-14 px-3" @click="buscarLeechero()">
                        Validar
                    </button>
                </div>
            </div>
        </div>
        <div v-if="resultadosBusqueda == false">
            <span class="text-danger w-100 f-15 row mx-0 j-center">Lo sentimos, no se encontraron resultados</span>
        </div>
        <div v-if="busquedaLeechero">
            <div v-for="(data, idx) in listaLeecheros" :key="idx" class="row mx-0 j-center">
                <div class="col-auto px-0">
                    <img :src="data.logo" class="br-8 obj-cover border" width="142" height="142" />
                </div>
                <div class="col-6">
                    <div class="row mx-0">
                        <p class="col-auto px-0 f-500 f-22">
                            {{ data.nombre }}
                        </p>
                        <div class="col-auto px-0 ml-auto d-middle f-15">
                            <i class="icon-star text-yellow" />
                            <span class="ml-2 f-500">{{ data.calificacion }}</span>
                            <span>({{ data.calificacion_cantidad }})</span>
                        </div>
                    </div>
                    <p class="f-14 my-2">
                        {{ data.descripcion }}
                    </p>
                    <div class="row mx-0 align-items-center mt-2">
                        <div class="col-auto px-0 d-middle px-0">
                            <i class="icon-truck-outline mr-2" />
                            <span v-if="data.domicilio_gratis == 0" class="f-15">Domicilio Gratis</span>
                            <span v-else class="f-15"> {{ formatNumero(data.domicilio_gratis, 0, true) }}</span>
                        </div>
                        <div class="col-auto ml-auto px-0 d-middle">
                            <i class="icon-map-pin f-17" />
                            <span class="f-15">A <span class="f-500">{{ formatNumero(data.distancia, 0, false) }} m.</span> de ti </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 j-center mt-3">
                <div class="col-12 px-0">
                    <div class="btn-general" @click="agregarLeechero">
                        seleccionar
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Leechero from '~/services/leechero/leechero'
export default {
    data(){
        return {
            busquedaLeechero: null,
            infoLeechero: {},
            listaLeecheros:[],
            codigo_leechero:'',
            resultadosBusqueda : true
        }
    },
    methods: {
        toggle(){
            this.$refs.modalSeleccionarLeechero.toggle();
        },
        async buscarLeechero(){
            try {
                let params = {
                    codigo_leechero: this.codigo_leechero
                }
                console.log(this.codigo_leechero)
                const { data } = await Leechero.buscarLeechero(params);
                this.listaLeecheros = data.datosLeechero;
                this.resultadosBusqueda = true,
                this.busquedaLeechero = true

            } catch (e){
                this.listaLeecheros = [],
                this.resultadosBusqueda = false,
                this.errorCatch(e)
            }          
        },
        async agregarLeechero(){
            try {
                let params = {
                    codigo_leechero: this.codigo_leechero,
                }
                const { data } = await Leechero.agregarLeechero(params);
                
                await this.$store.dispatch('auth/consultarUsuario')
                this.listaLeecheros = [],
                this.busquedaLeechero = '',
                this.notificacion('Exito',`${this.$config.vendedor} agregado correctamente`,'success');
                this.$emit('leecheroSeleccionado')
                this.$refs.modalSeleccionarLeechero.toggle();

            } catch (e){
                this.errorCatch(e)
            }          
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-buscar{  
    background-color: var(--color-general) !important;
    position: absolute;
    right: 4px;
    height: 36px;
    top: 3px;
    border: 0px;
    border-radius: 20px;
    color: #FFFFFF;
    height: 34px;
    &:focus{
        outline: none !important;
    }
}
</style>